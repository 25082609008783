.modal {
  width: 800px;
  height: 650px;
  position: relative;
  cursor: default;
  font-size: 15px;
}

.modal_dark {
  background-color: #1e1e1e;
}

.modal_light {
  background-color: #f4f4f4;
}

.modal__overlay {
  z-index: 7;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  transition: visibility 0.3s, opacity 0.3s;
  cursor: pointer;
}

.modal__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  fill: #1e1e1e;
  transition: 0.3s;
  cursor: pointer;
}

.modal__close-dark {
  fill: #fff;
}

.modal__close-button:hover {
  fill: #ff3737;
}

@media screen and (max-width: 800px) {
  .modal {
    width: 95%;
    height: 80%;
  }
}

@media screen and (max-height: 680px) {
  .modal {
    width: 95%;
    height: 95%;
  }
}
