.skills {
  margin: 0;
  padding: 100px 100px 100px;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  font-style: normal;
}

.skills__title {
  margin: 0 0 42px;
  font-weight: 700;
  font-size: 60px;
  line-height: 69px;
}

.skills__title_red {
  color: #ff3737;
}

.skills__title_yellow {
  color: #4e1bb4;
}

.skills__description {
  margin: 0;
  max-width: 550px;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
}

.skills__undertext {
  margin: 0;
  width: 60%;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
}

.skills__table {
  display: -ms-grid;
  display: grid;
  margin: 0 0 20px;
  -ms-grid-columns: 125px 10px 125px 10px 125px 10px 125px 10px 125px 10px 125px
    10px 125px 10px 125px;
  grid-template-columns: repeat(8, 125px);
  -ms-grid-rows: 125px 10px 125px 10px 125px 10px 125px;
  grid-template-rows: repeat(4, 125px);
  grid-gap: 10px;
  max-width: 1200px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.skills__spacer_top {
  -ms-grid-column: 2;
  -ms-grid-column-span: 6;
  grid-column: 2 / span 6;
  -ms-grid-row: 1;
  grid-row: 1;
}

.skills__spacer_middle {
  -ms-grid-column: 3;
  -ms-grid-column-span: 5;
  grid-column: 3 / span 5;
  -ms-grid-row: 2;
  grid-row: 2;
}

.skills__spacer_bottom {
  -ms-grid-column: 4;
  -ms-grid-column-span: 3;
  grid-column: 4 / span 3;
  -ms-grid-row: 3;
  grid-row: 3;
}

.skills__spacer_left-square {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 5;
  grid-row: 5;
}

.skills__spacer_right-square {
  -ms-grid-column: 8;
  grid-column: 8;
  -ms-grid-row: 5;
  grid-row: 5;
}

@media (max-width: 1150px) {
  .skills__table {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 125px 10px 125px 10px 125px 10px 125px 10px 125px 10px
      125px;
    grid-template-columns: repeat(6, 125px);
    -ms-grid-rows: 125px 10px 125px;
    grid-template-rows: repeat(2, 125px);
  }

  .skills__spacer_top {
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-column: 2 / span 4;
    -ms-grid-row: 1;
    grid-row: 1;
  }

  .skills__spacer_middle {
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    grid-column: 3 / span 2;
    -ms-grid-row: 2;
    grid-row: 2;
  }

  .skills__spacer_bottom {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 4;
    grid-row: 4;
  }

  .skills__spacer_left-square {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / span 2;
    -ms-grid-row: 5;
    grid-row: 5;
  }

  .skills__spacer_right-square {
    -ms-grid-column: 6;
    grid-column: 6;
    -ms-grid-row: 4;
    grid-row: 4;
  }
}

@media (max-width: 860px) {
  .skills__table {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 125px 10px 125px 10px 125px 10px 125px;
    grid-template-columns: repeat(4, 125px);
    -ms-grid-rows: 125px 10px 125px;
    grid-template-rows: repeat(2, 125px);
  }

  .skills__spacer_top {
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-column: 2 / span 2;
    -ms-grid-row: 1;
    grid-row: 1;
  }

  .skills__spacer_middle {
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-column: 2 / span 2;
    -ms-grid-row: 2;
    grid-row: 2;
  }

  .skills__spacer_left-square {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 6;
    grid-row: 6;
  }
  .skills__spacer_right-square,
  .skills__spacer_bottom {
    display: none;
  }

  .skills__undertext {
    font-size: 10px;
    line-height: 11px;
  }
}

@media (max-width: 700px) {
  .skills__title {
    font-size: 40px;
    line-height: 46px;
    margin: 0 0 56px;
  }

  .skills__description {
    width: 270px;
    font-size: 20px;
    line-height: 23px;
    margin: 0 0 50px;
  }

  .skills__table {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 125px 10px 125px;
    grid-template-columns: repeat(2, 125px);
    -ms-grid-rows: 125px 10px 125px;
    grid-template-rows: repeat(2, 125px);
  }

  .skills__spacer_top,
  .skills__spacer_middle,
  .skills__spacer_left-square {
    display: none;
  }
}
