.experience-card {
  position: relative;
  width: 49.8%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.experience-card__container {
  border-radius: 25px;
  padding: 13px 37px;
}

.experience-card__title {
  margin: 0;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
}

.experience-card__title_red {
  color: #ff3737;
}

.experience-card__title_yellow {
  color: #4e1bb4;
}

.experience-card__type {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
}

.experience-card__position {
  margin: 0 0 5px;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
}

.experience-card__content {
  margin: 5px 0 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

@media (max-width: 1150px) {
  .experience-card {
    width: 80%;
  }
}

@media (max-width: 710px) {
  .experience-card {
    margin: 0;
    width: 100%;
  }
  .experience-card__title {
    font-size: 25px;
    line-height: 29px;
  }

  .experience-card__type {
    font-size: 10px;
    line-height: 11px;
  }

  .experience-card__position {
    font-size: 15px;
    line-height: 17px;
  }

  .experience-card__content {
    font-size: 15px;
    line-height: 17px;
  }
}
