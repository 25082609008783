.experience {
  position: relative;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  padding: 50px 25px 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.experience__title {
  font-weight: 700;
  font-size: 60px;
  line-height: 69px;
  margin: 0 0 30px;
}

.experience__title_red {
  color: #ff3737;
}

.experience__title_yellow {
  color: #4e1bb4;
}

.experience__content {
  position: relative;
  z-index: 2;
  width: 85%;
  gap: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 70px 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.experience__line {
  position: absolute;
  height: 100%;
  top: 0;
  right: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.experience__footer {
  position: relative;
  z-index: 2;
  max-width: 500px;
  border-radius: 25px;
  padding: 5px;
}

.experience__footer-text {
  margin: 0;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
}

.experience__circle {
  position: absolute;
  right: -200px;
  top: -20px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
}

.experience__circle_red {
  background: #ff3737;
}

.experience__circle_yellow {
  background: #4e1bb4;
}

.experience__triangle {
  position: absolute;
  left: 20%;
  top: 38%;
  width: 0;
  height: 0;
  border-left: 125px solid transparent;
  border-right: 125px solid transparent;
  border-bottom: 150px solid #141414;
  -webkit-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.experience__square {
  position: absolute;
  right: -223px;
  bottom: 3%;
  width: 400px;
  height: 400px;
  background: #d9d9d9;
  -webkit-transform: rotate(-56deg);
  -ms-transform: rotate(-56deg);
  transform: rotate(-56deg);
}

@media (max-width: 1150px) {
  .experience__line {
    display: none;
  }

  .experience__triangle {
    left: 1%;
  }

  .experience__square {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 860px) {
  .experience__title {
    margin: 0 0 37px;
    font-size: 40px;
    line-height: 46px;
  }

  .experience__content {
    padding: 0 25px 30px;
    gap: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }

  .experience__circle {
    width: 150px;
    height: 150px;
    right: -50px;
    top: 10%;
  }

  .experience__triangle {
    left: -10%;
    top: 38%;
    width: 0;
    height: 0;
    border-left: 75px solid transparent;
    border-right: 75px solid transparent;
    border-bottom: 80px solid #141414;
  }

  .experience__square {
    width: 150px;
    height: 150px;
    right: -75px;
    bottom: 3%;
  }

  .experience__footer {
    max-width: 300px;
    border-radius: 20px;
  }

  .experience__footer-text {
    font-size: 20px;
    line-height: 23px;
  }
}
