.projects {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  margin: 0 auto;
  min-height: 100vh;
  width: 80%;
  padding: 80px 5px 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.projects__title {
  margin: 0 0 50px;
  font-weight: 700;
  font-size: 60px;
  line-height: 69px;
}

.projects__title_red {
  color: #ff3737;
}

.projects__title_yellow {
  color: #4e1bb4;
}

.project__content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}

.projects__list {
  list-style: none;
  padding: 0;
  margin: 10px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  justify-content: left;
  flex-wrap: wrap;
  column-gap: 5%;
  row-gap: 60px;
}

@media (max-width: 1250px) {
  .projects__list {
    max-width: 645px;
    column-gap: 45px;
  }
}

@media (max-width: 860px) {
  .projects {
    width: 100%;
    padding: 65px 0;
  }

  .projects__title {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 30px;
  }

  .projects__list {
    margin-top: 60px;
    justify-content: center;
  }
}
