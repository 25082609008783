.loading-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1%;
  background: white;
  transition: 0.3s;
}

.loading-screen_invisible {
  display: none;
}

.loading-screen__round {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ff3737;
  -webkit-animation: shuffle-round 2s infinite ease-in-out alternate;
  animation: shuffle-round 2s infinite ease-in-out alternate;
}

.loading-screen__square {
  width: 100px;
  height: 100px;
  background-color: #d9d9d9;
  -webkit-animation: shuffle-square 2s infinite ease-in-out alternate;
  animation: shuffle-square 2s infinite ease-in-out alternate;
}

.loading-screen__triangle {
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 100px solid #141414;
  -webkit-animation: shuffle-triangle 2s infinite ease-in-out alternate;
  animation: shuffle-triangle 2s infinite ease-in-out alternate;
}

@-webkit-keyframes shuffle-round {
  0% {
    -webkit-transform: translateX(calc(0));
    transform: translateX(calc(0));
  }
  50% {
    -webkit-transform: translateX(calc(120px + 1%));
    transform: translateX(calc(120px + 1%));
  }
  100% {
    -webkit-transform: translateX(calc(-100px + 2%));
    transform: translateX(calc(-100px + 2%));
  }
}

@keyframes shuffle-round {
  0% {
    -webkit-transform: translateX(calc(0));
    transform: translateX(calc(0));
  }
  50% {
    -webkit-transform: translateX(calc(120px + 1%));
    transform: translateX(calc(120px + 1%));
  }
  100% {
    -webkit-transform: translateX(calc(-100px + 2%));
    transform: translateX(calc(-100px + 2%));
  }
}

@-webkit-keyframes shuffle-square {
  0% {
    -webkit-transform: translateX(calc(0));
    transform: translateX(calc(0));
  }
  50% {
    -webkit-transform: translateX(calc(100px + 1%));
    transform: translateX(calc(100px + 1%));
  }
  100% {
    -webkit-transform: translateX(calc(200px + 1%));
    transform: translateX(calc(200px + 1%));
  }
}

@keyframes shuffle-square {
  0% {
    -webkit-transform: translateX(calc(0));
    transform: translateX(calc(0));
  }
  50% {
    -webkit-transform: translateX(calc(100px + 1%));
    transform: translateX(calc(100px + 1%));
  }
  100% {
    -webkit-transform: translateX(calc(200px + 1%));
    transform: translateX(calc(200px + 1%));
  }
}

@-webkit-keyframes shuffle-triangle {
  0% {
    -webkit-transform: translateX(calc(0));
    transform: translateX(calc(0));
  }
  50% {
    -webkit-transform: translateX(calc(-200px + 1%));
    transform: translateX(calc(-200px + 1%));
  }
  100% {
    -webkit-transform: translateX(calc(-100px + 2%));
    transform: translateX(calc(-100px + 2%));
  }
}

@keyframes shuffle-triangle {
  0% {
    -webkit-transform: translateX(calc(0));
    transform: translateX(calc(0));
  }
  50% {
    -webkit-transform: translateX(calc(-200px + 1%));
    transform: translateX(calc(-200px + 1%));
  }
  100% {
    -webkit-transform: translateX(calc(-100px + 2%));
    transform: translateX(calc(-100px + 2%));
  }
}
