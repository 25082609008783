.nav {
  list-style: none;
  z-index: 3;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 5em;
  line-height: 1.3em;
  position: fixed;
  padding: 100px auto 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: var(--dark-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7%;
  margin: 0;
  transform-origin: top;
  transition: 0.5s;
}

.nav__item {
  padding: none;
  cursor: pointer;
  transition: 0.3s;
  transform: none;
  height: 50px;
}

.nav__item:hover {
  font-size: 1.2em;
}

.nav_light {
  background: var(--dark-background);
}

.nav_dark {
  background: var(--light-background);
}

@media screen and (max-height: 700px), (max-width: 800px) {
  .nav {
    font-size: 3em;
    gap: 3%;
  }
}
