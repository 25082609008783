.skills-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 6px;
  padding: 15px;
  text-align: start;
  text-decoration: none;
  color: white;
  width: 125px;
  height: 125px;
  background: none;
  border-radius: 25%;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.skills-card__image {
  height: 60px;
}

.skills-card__name {
  margin: 0;
}

.skills-card:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
