.menu {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 1%;
  top: 2%;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  z-index: 5;
  transition: 0.1s;
}

.menu__input {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  -webkit-touch-callout: none;
}

.menu__burger {
  display: block;
  position: absolute;
  left: 30px;
  top: 36.5px;
  transform: translateX(-27.5%);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: -30% auto 0 auto;
  cursor: pointer;
}

.menu__burger .menu__line {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s, opacity 0.55s ease;
}

.menu__line_dark {
  background-color: #fff;
}

.menu__line_light {
  background-color: #000;
}

.menu__burger .menu__line:first-child {
  transform-origin: 0% 0%;
}

.menu__burger .menu__line:last-child {
  transform-origin: 0% 100%;
}

.menu__input:checked + .menu__burger .menu__line {
  transition: 0.5s;
  opacity: 1;
  transform: rotate(45deg) translate(0px, -3px);
}

.menu__input:checked + .menu__burger .menu__line:last-child {
  transform: rotate(-45deg) translate(0, 3px);
}

.menu__input:checked + .menu__burger .menu__line:nth-child(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.menu__input:checked + .menu__burger:hover .menu__line {
  background-color: #ff3737;
}
