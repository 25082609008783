.project-card {
  min-width: 300px;
  height: 450px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  cursor: pointer;
}

.project-card__content {
  width: 100%;
  height: 100%;
  z-index: 2;
}

.project-card__fade {
  content: "";
  width: 100%;
  height: 100%;
  top: 10px;
  left: 10px;
  position: absolute;
  transition: 0.3s;
}

.project-card__overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: #1a1a1a;
  opacity: 0;
  transition: 0.3s;
}

.project-card:hover .project-card__fade {
  top: 20px;
  left: 20px;
}

.project-card:hover .project-card__overlay {
  opacity: 0.4;
}

.project-card:active .project-card__fade {
  transition: 0.1s;
  transform: translate(-20px, -20px);
}

.project-card__dark-theme {
  background-color: #ff3737;
}

.project-card__light-theme {
  background-color: #4e1bb4;
}

.project-card__image {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.project-card__title {
  margin: 0 0 20px;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
}

.project-card__title_red {
  color: #ff3737;
}

.project-card__title_yellow {
  color: #f2ff61;
}

.project-card__description {
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}

.project-card__tech-list {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

.project-card__image-container {
  position: relative;
  width: 400px;
  height: 250px;
}

.project-card__image-overlay {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 50px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.project-card__image-container:hover .project-card__image-overlay {
  opacity: 1;
}

.project-card__link {
  width: 40px;
  height: 40px;
  cursor: pointer;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.project-card__link-image {
  width: 100%;
  height: 100%;
}

.project-card__link:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
