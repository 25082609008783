.header {
  height: 100vh;
  width: 100%;
  padding-left: 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 45px;
  line-height: 90px;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.header__title {
  margin: 0;
  max-width: 790px;
}

.header__title_red {
  color: #ff3737;
  margin: 0;
}

.header__title_purple {
  color: #4e1bb4;
  margin: 0;
}

.heaeder__menu {
  font-size: 15px;
}

.header__nav {
  padding: 0;
}

.header__shapes {
  max-width: 40%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -5%;
}

@media (max-width: 1350px) {
  .header__shapes {
    right: -5%;
  }
}

@media (max-width: 1200px) {
  .header__shapes {
    right: -30%;
    max-width: 50%;
  }
}

@media (max-width: 850px) {
  .header {
    padding: 90px 20px 150px;
    height: 100vh;
  }

  .header__title {
    font-size: 45px;
    line-height: 50px;
  }

  .header__cloud {
    width: 250px;
  }

  .header__cloud:nth-child(1) {
    top: -100px;
    left: -150px;
  }

  .header__cloud:nth-child(2) {
    bottom: -100px;
    left: -100px;
  }

  .header__cloud:nth-child(3) {
    bottom: 75px;
    right: -125px;
  }

  .header__scrolldown {
    font-size: 20px;
    line-height: 23px;
  }

  .header__pointer-container {
    width: 30px;
    height: 30px;
  }
}
