@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Ubuntu-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Ubuntu-Medium.ttf");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Ubuntu-Bold.ttf");
}
