:root {
  /* Variables for background */
  --dark-background: #1e1e1e;
  --light-background: #fbfbfb;
  --primary-purple: #4e1bb4;
  --primary-red: #ff3737;
}

html {
  scroll-behavior: smooth;
}

.page {
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 100%;
  background: var(--dark-background);
  color: white;
}
