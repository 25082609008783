.footer {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  position: relative;
  min-height: 100vh;
  padding-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.footer__content {
  position: relative;
  z-index: 2;
}

.footer__title {
  margin: 50px auto 40px;
  font-weight: 700;
  font-size: 60px;
  line-height: 69px;
  text-align: center;
}

.footer__title_red {
  color: #ff3737;
}

.footer__title_purple {
  color: #4e1bb4;
}

.footer__text {
  margin: 0 auto 100px;
  width: 75%;
  font-weight: 500;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
}

.footer__links {
  margin: 0 auto 100px;
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 100px;
}

.footer__link {
  width: 100px;
  height: 100px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.footer__link-icon {
  width: 100%;
  height: 100%;
}

.footer__link:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.footer__copyright,
.footer__date {
  margin: 0;
  position: absolute;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
}

.footer__copyright {
  bottom: calc(23px + 5%);
}

.footer__date {
  bottom: 5%;
}

.footer__square {
  position: absolute;
  top: 0;
  left: -162px;
  width: 400px;
  height: 400px;
  -webkit-transform: rotate(-56deg);
  -ms-transform: rotate(-56deg);
  transform: rotate(-56deg);
}

.footer__triangle {
  position: absolute;
  bottom: -10%;
  right: 16%;
  width: 0;
  height: 0;
  border-left: 175px solid transparent;
  border-right: 175px solid transparent;

  -webkit-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  transform: rotate(-25deg);
}

.footer__triangle_red {
  border-bottom: 250px solid #ff3737;
}

.footer__triangle_purple {
  border-bottom: 250px solid #4e1bb4;
}

@media (max-width: 1000px) {
  .footer__links {
    gap: 60px;
  }

  .footer__link {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 860px) {
  .footer__title {
    max-width: 90%;
    margin-bottom: 40px;
    font-size: 35px;
    line-height: 40px;
  }

  .footer__text {
    margin-bottom: 45px;
    font-size: 15px;
    line-height: 18px;
  }

  .footer__links {
    margin-bottom: 70px;
    gap: 20px;
  }

  .footer__link {
    width: 40px;
    height: 40px;
  }

  .footer__square {
    left: -50px;
    width: 150px;
    height: 150px;
  }

  .footer__triangle {
    bottom: 20%;
    border-left: 75px solid transparent;
    border-right: 75px solid transparent;
    border-bottom: 100px solid #ff3737;
  }

  .footer__copyright,
  .footer__date {
    font-size: 10px;
    line-height: 11px;
  }

  .footer__copyright {
    bottom: calc(11px + 5%);
  }
}
