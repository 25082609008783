.about {
  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 140px 120px 0;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  grid-template-areas: "title image" "description image" "description image" "description image";
  gap: 10px;
}

.about__title {
  grid-area: title;
  font-weight: 700;
  font-size: 60px;
  line-height: 69px;
}

.about__title_red {
  color: #ff3737;
}

.about__title_yellow {
  color: #4e1bb4;
}

.about__description {
  grid-area: description;
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  position: relative;
  z-index: 3;
}

.about__image {
  grid-area: image;
}

.about__rectangle {
  position: absolute;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: -217px;
  top: 700px;
  z-index: 1;
  width: 500px;
  height: 300px;
  background-color: #ff3737;
}

.about__rectangle_hidden {
  visibility: hidden;
}

@media (max-width: 1350px) {
  .about {
    grid-template-areas: "title" "image" "description";
    gap: 5%;
  }

  .about__image {
    width: 65%;
    margin: 0 auto 0;
  }

  .about__title {
    text-align: center;
    margin: 0;
  }

  .about__description {
    margin: 0 auto 0;
    text-align: center;
  }

  .about__rectangle {
    top: 1000px;
  }
}

@media (max-width: 700px) {
  .about {
    padding: 100px 15px 100px;
  }

  .about__title {
    font-size: 40px;
    line-height: 46px;
  }

  .about__description {
    font-size: 20px;
    line-height: 23px;
    width: 80%;
  }

  .about__rectangle {
    width: 220px;
    height: 150px;
    top: 800px;
    left: -90px;
  }
}
