.modal-project {
  display: flex;
  gap: 15px;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.modal-project__image {
  min-width: 52%;
  height: 100%;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: white;
  text-decoration: none;

  filter: blur(3px);
  -webkit-filter: blur(3px);
  transition: filter 0.6s;
}

.modal-project__image:hover {
  filter: blur(0);
  -webkit-filter: blur(0);
}

.modal-project__content {
  max-width: 100%;
  padding: 60px 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 25px;
}

.modal-project__title {
  margin: 0;
  font-size: 1.6em;
  line-height: 1.2em;
}

.modal-project__description {
  margin: 0;
  font-size: 1.1em;
  line-height: 1.2em;
}

.modal-project__tech-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.modal-project__tech-image {
  height: 25px;
}

.modal-project__link {
  margin: 0;
  text-decoration: none;
  transition: 0.3s;
}

.modal-project__link_dark {
  color: black;
}

.modal-project__link_light {
  color: white;
}

.modal-project__link:hover {
  color: #ff3737;
}

@media screen and (max-width: 600px) {
  .modal-project {
    flex-direction: column;
    font-size: 14px;
    gap: 10px;
  }

  .modal-project__content {
    padding-top: 10px;
  }

  .modal-project__image {
    height: 150px;
    background-position: top;

    filter: blur(0);
    -webkit-filter: blur(0);
  }
}

@media screen and (max-height: 680px) {
  .modal-project__image {
    display: none;
  }
}
